import { memo, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { TfiHeadphoneAlt } from 'react-icons/tfi';
import { AgGridReact } from 'ag-grid-react';
import { Radio } from 'antd';
import moment from 'moment';

import BreadCrumb from '../../components/breadcrumb';
import SectionInfo from '../../components/sectionInfo';
import InnerHeader from '../../components/innerHeader';
import Cascader from '../../components/cascader';
import EdenContext from '../../context/edenContext';
import LoadingMessage from '../../components/loaderMessage';
import ErrorMessage from '../../components/errorMessage';
import NoData from '../../components/nodata';
import { RadioTwo } from '../../components/radio';
import HelpDeskTrackerContext from '../../context/helpdeskTrackerContext';
import { isNotEmptyArray } from '../../utils';
import ComposeTicket from './ComposeTicket';
import { dateFilterParams } from '../../filters';
import MailPreview from '../../components/MailPreview';
import NotificationBar from '../../components/notificationBar';

import styles from './helpDeskTracker.module.scss';

const HelpDeskTracker = () => {
	const gridRef = useRef();
	const { userType, srData } = useContext(EdenContext);
	const {
		data,
		isLoading,
		error,
		getData,
		cascaderOption,
		setCascaderOption,
		ticketsView,
		setTicketsView,
		mailError,
	} = useContext(HelpDeskTrackerContext);

	const [gridApi, setGridApi] = useState(null);

	const [columnsDefs] = useState([
		{ headerName: 'Ticket ID', field: 'TICKET_ID', editable: false },
		{ headerName: 'Requester', field: 'EMPLOYEE_NAME', editable: false },
		{
			headerName: 'Created Date Time',
			field: 'CREATED_AT',
			editable: false,
			sort: 'desc',
			minWidth: 180,
			cellRenderer: (p) => (
				<span className={styles['font-weight']}>
					{p.value
						? moment
								.utc(p.value)
								.tz('Asia/Singapore')
								.format('MMM-DD-YYYY hh:mm A')
						: ''}
				</span>
			),
			filter: 'agDateColumnFilter',
			filterParams: dateFilterParams,
			suppressHeaderMenuButton: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true,
			},
		},
		{ headerName: 'Description', field: 'DESCRIPTION', editable: false },
		{ headerName: 'Ticket Type', field: 'TICKET_TYPE', editable: false },
		{
			headerName: 'Assigned To',
			field: 'ASSIGNED_TO',
			cellEditor: 'agRichSelectCellEditor',
			cellEditorParams: {
				values: ['Arun', 'Gautam', 'Marc', 'Nitin', 'Zeeshan'],
				searchType: 'match',
				allowTyping: true,
				filterList: true,
				highlightMatch: true,
				valueListMaxHeight: 220,
				valueListMaxWidth: 120,
			},
		},
		{
			headerName: 'Ticket Status',
			field: 'STATUS',
			cellEditor: 'agRichSelectCellEditor',
			cellEditorParams: {
				values: [
					'Open/New ',
					'In-progress',
					'Waiting for Approval',
					'Escalated to Third party (Focus/CRM/Microsoft)',
					'On hold',
					'Closed and Resolved',
					'Closed and Not Resolved',
				],
				searchType: 'match',
				allowTyping: true,
				filterList: true,
				highlightMatch: true,
				valueListMaxHeight: 220,
				valueListMaxWidth: 290,
			},
		},
		{
			headerName: 'Attachment (Email)',
			field: 'ATTACH_EMAIL_ID',
			editable: false,
			minWidth: 80,
			floatingFilter: false,
			suppressHeaderMenuButton: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true,
			},
			cellRenderer: memo(MailPreview),
			cellStyle: { padding: '4px 0 0 25px' },
		},
		{
			headerName: 'Attachment (Files)',
			field: 'ATTACHMENT_FILES',
			editable: false,
		},
		{
			headerName: 'IT HD Modified Date',
			field: 'MODIFIED_DATE',
			editable: false,
			minWidth: 180,
			cellRenderer: (p) => (
				<span className={styles['font-weight']}>
					{p.value
						? moment
								.utc(p.value)
								.tz('Asia/Singapore')
								.format('MMM-DD-YYYY hh:mm A')
						: ''}
				</span>
			),
			filter: 'agDateColumnFilter',
			filterParams: dateFilterParams,
			suppressHeaderMenuButton: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true,
			},
		},
		{ headerName: 'Days Open', field: 'DAYS_OPEN', editable: false },
	]);

	useEffect(() => {
		getData(); //eslint-disable-next-line
	}, [ticketsView, cascaderOption]);

	const onGridReady = (params) => {
		setGridApi(params.api);
	};

	//Export to excel
	const exportToExcel = () => {
		const helpDeskTrackerExcelParams = {
			sheetName: 'Help Desk Tracker',
			fileName: 'Help Desk Tracker.xlsx',
		};
		gridApi.exportDataAsExcel(helpDeskTrackerExcelParams);
	};

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			resizable: true,
			floatingFilter: true,
			suppressMovable: true,
			filter: 'agMultiColumnFilter',
			editable: userType === 'admin',
			filterParams: {
				filters: [
					{
						filter: 'agTextColumnFilter',
						filterParams: {
							applyMiniFilterWhileTyping: true,
						},
					},
					{
						filter: 'agSetColumnFilter',
						filterParams: {
							buttons: ['reset'],
							applyMiniFilterWhileTyping: true,
						},
					},
				],
			},
		}),
		[userType]
	);

	useEffect(() => {
		const handleKeyPress = (event) => {
			if (event.ctrlKey && event.shiftKey && event.key === 'L') {
				gridApi.setFilterModel(null);
				gridApi?.setQuickFilter('');
			}
		};

		window.addEventListener('keydown', handleKeyPress);

		return () => {
			window.removeEventListener('keydown', handleKeyPress);
		};
	}, [gridApi]);

	// For cascader
	const onChange = (value, selectedOptions) => {
		setCascaderOption(selectedOptions[0]?.id);
	};

	// on clear cascader
	const clearCascader = () => {
		setCascaderOption('');
	};

	const handleTicketsView = (e) => {
		setTicketsView(e.target.value);
	};

	return (
		<div className={styles['wrapper']}>
			<BreadCrumb
				route1="HD Tracker"
				icon={<TfiHeadphoneAlt style={{ fontSize: '20px' }} />}
			/>
			<SectionInfo
				removeshortcutkeys
				title="HD Tracker"
				info="This help desk section allows you to add a new Help Desk ticket and view/manage existing tickets."
			/>
			<InnerHeader exportToExcel={exportToExcel} />

			<div className={styles['help-desk-tracker']}>
				{mailError && (
					<NotificationBar
						redBar
						message={`Oops! There seems to be a problem with Eden’s email system. Please send your ticket manually to tickets@supremecomponents.com.​`}
					/>
				)}
				<div className={styles['dropdown-wrapper']}>
					<Cascader
						placeholder="SR, SM"
						options={srData?.map((el) => ({
							value: el?.salesRepName,
							label: el?.salesRepName,
							id: el?.salesRepCrmId,
							email: el?.salesRepEmail,
						}))}
						onChange={onChange}
						onClear={clearCascader}
						changeOnSelect={true}
						disabled={isLoading}
						title="Drill down by:"
					/>
				</div>
				<Radio.Group
					defaultValue={ticketsView}
					onChange={handleTicketsView}
					disabled={isLoading}
					style={{ marginBottom: '10px' }}
				>
					<RadioTwo value="OPEN">View Open Tickets</RadioTwo>
					<RadioTwo value="">View All Tickets</RadioTwo>
					<RadioTwo value="CLOSED">View Closed Tickets</RadioTwo>
				</Radio.Group>
				{isLoading && (
					<div className={styles['loading-wrapper']}>
						<LoadingMessage message="Loading..." />
					</div>
				)}
				{error && !isLoading && (
					<div className={styles['error-wrapper']}>
						<ErrorMessage message="Snap! That sound could be that of your data endpoint going kaput. But don’t worry our engineers are already on it getting it fixed." />
					</div>
				)}
				{!isNotEmptyArray(data) && !isLoading && !error && (
					<div className={styles['no-data-wrapper']}>
						<NoData message="No Data" />
					</div>
				)}
				{!isLoading && !error && isNotEmptyArray(data) && (
					<div className={`ag-theme-custom ${styles['table-wrapper']} `}>
						<ComposeTicket />
						<AgGridReact
							ref={gridRef}
							onGridReady={onGridReady}
							rowData={data}
							columnDefs={columnsDefs}
							defaultColDef={defaultColDef}
							animateRows={true}
							pagination={true}
							floatingFilter={true}
							tooltipShowDelay={1500}
							enableRangeSelection={true}
							paginationPageSizeSelector={false}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default HelpDeskTracker;
