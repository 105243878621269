import axios from 'axios';
import { createContext, useContext, useRef, useState } from 'react';
import moment from 'moment-timezone';

import EdenContext from '../edenContext';

const CustomerOutreachContext = createContext();

export const CustomerOutreachProvider = ({ children }) => {
	const { userMail, userType, userInfo } = useContext(EdenContext);

	const [customerSummary, setCustomerSummary] = useState(false);
	const [customerOutreach, setCustomerOutreach] = useState(true);
	const [data, setData] = useState([{}]);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [sendMailCount, setSendMailCount] = useState(0);
	const [contactInfoUpdate, setContactInfoUpdate] = useState(null);
	const [accounts, setAccounts] = useState([]);
	const [selectedRows, setSelectedRows] = useState([]);
	const abortControllerRef = useRef(null);

	const SINGAPORE_TZ = 'Asia/Singapore';

	const preprocessData = (data, dateFields) => {
		return data.map((row) => {
			const newRow = { ...row };
			dateFields.forEach((field) => {
				if (row[field]) {
					const dateValue = moment.tz(row[field], SINGAPORE_TZ).startOf('day');
					const today = moment.tz(SINGAPORE_TZ).startOf('day');
					const openDay = today.diff(dateValue, 'days');

					newRow[`${field}_label`] = openDay;
				} else {
					newRow[`${field}_label`] = null;
				}
			});
			return newRow;
		});
	};

	const getData = () => {
		setSendMailCount(0);
		setIsLoading(true);
		setSelectedRows([]);

		if (abortControllerRef.current) {
			abortControllerRef.current.abort();
		}
		abortControllerRef.current = new AbortController();
		const signal = abortControllerRef.current.signal;

		axios
			.get(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=${
					customerOutreach
						? 'get_customer_outreach'
						: 'get_customer_outreach_summary'
				}&sales_rep_email=${userMail}&is_admin=${
					userType === 'admin' || userType === 'ceo' ? true : false
				}&emails=${
					userType === 'sr'
						? userMail
						: userType === 'sm'
						? userInfo?.EmailFilters?.ViewAsSM
						: userType === 'ss'
						? userInfo?.EmailFilters?.ViewAsSS
						: userType === 'admin' || userType === 'ceo'
						? userInfo?.EmailFilters?.ViewAsAdmin
						: ''
				}`,
				{ signal }
			)
			.then((response) => {
				setData(
					preprocessData(response.data?.data, [
						'last_incoming_date',
						'last_outgoing_date',
						'last_outgoing_outreach_date',
					])
				);

				setAccounts([...new Set(response.data?.data?.map((el) => el.Account))]);
				setError(null);
				setIsLoading(false);
			})
			.catch((err) => {
				if (axios.isCancel(err)) {
					console.log('Request canceled:', err.message);
				} else {
					setError(err);
					setData(null);
					setIsLoading(false);
				}
			});
	};

	const showCustomerSummary = () => {
		setCustomerSummary(true);
		setCustomerOutreach(false);
	};

	const showCustomerOutreach = () => {
		setCustomerSummary(false);
		setCustomerOutreach(true);
	};

	return (
		<CustomerOutreachContext.Provider
			value={{
				customerSummary,
				customerOutreach,
				showCustomerSummary,
				showCustomerOutreach,
				getData,
				data,
				setData,
				isLoading,
				error,
				sendMailCount,
				setSendMailCount,
				contactInfoUpdate,
				setContactInfoUpdate,
				accounts,
				selectedRows,
				setSelectedRows,
			}}
		>
			{children}
		</CustomerOutreachContext.Provider>
	);
};

export default CustomerOutreachContext;
