import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { MdOutlineAdminPanelSettings } from 'react-icons/md';
import axios from 'axios';
import moment from 'moment';
import { Divider } from 'antd';

import EdenContext from '../../../context/edenContext';
import BreadCrumb from '../../../components/breadcrumb';
import SectionInfo from '../../../components/sectionInfo';
import LoadingMessage from '../../../components/loaderMessage';
import ErrorMessage from '../../../components/errorMessage';
import { isNotEmptyArray } from '../../../utils';
import NoData from '../../../components/nodata';
import AccessDenied from '../../../components/accessDenied';
import { dateFilterParams } from '../../../filters';
import CheckBox from '../../../components/checkBox';
import NotificationBar from '../../../components/notificationBar';
import ToolTip from '../../../components/tooltip';

import styles from './chipfinderOctapartSources.module.scss';

const ChipfinderOctapartSources = () => {
	const { userType, userMail } = useContext(EdenContext);
	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [savingData, setSavingData] = useState(false);
	const [savingDataerror, setSavingDataError] = useState(null);
	const [octopartSource, setOctopartSource] = useState(null);
	const [findChipsSource, setFindChipsSource] = useState(null);
	const [commonSuppliers, setCommonSuppliers] = useState(null);
	const [gridApi, setGridApi] = useState(null);

	const [columnDefs] = useState([
		{ field: 'DISTRIBUTOR_NAME', headerName: 'Supplier Name​' },
		{ field: 'DATA_FROM', headerName: 'Source​' },
		{
			field: 'IS_ENABLED_FOR_CF',
			headerName: 'Activated',
			cellRenderer: 'agCheckboxCellRenderer',
			cellEditor: 'agCheckboxCellEditor',
			editable: true,
			maxWidth: 80,
		},
		{ headerName: 'Franchised?', field: 'IS_FRANCHISED' },
		{ headerName: 'Duplicated?', field: 'is_duplicated' },
		{
			headerName: 'Online/Offline',
			field: 'ONLINE_OR_OFFLINE',
			cellRenderer: 'agCheckboxCellRenderer',
			cellEditor: 'agCheckboxCellEditor',
			editable: true,
		},
		{ field: 'IS_DISTRIBUTOR_API', headerName: 'Is Distributor API' },
		{ headerName: 'CRM Name', field: 'CRM_ACCOUNT_NAME' },
		{
			field: 'DATE_TIME_ADDED',
			headerName: 'Date Added to Eden',
			filter: 'agDateColumnFilter',
			filterParams: dateFilterParams,
			cellRenderer: (p) => p.value && moment(p.value).format('MMM-DD-YYYY'),
		},
		{
			field: 'REMARKS',
			headerName: 'Notes',
			minWidth: 140,
			editable: true,
			cellRenderer: (p) => (
				<>
					{p?.value ? (
						<ToolTip title={p?.value} placement="left">
							{p?.value}
						</ToolTip>
					) : (
						''
					)}
				</>
			),
			cellEditor: 'agLargeTextCellEditor',
			cellEditorPopup: true,
			cellEditorParams: {
				maxLength: 256,
				rows: 5,
				cols: 50,
			},
		},
	]);

	const handleOctopart = (e) => {
		setOctopartSource(e.target.checked);
		axios.post(
			`https://internal.supremecomponents.com/api/externalgateway.php?route=set_cf_config&config_name=OCTOPART_AUTH&config_value=${
				e.target.checked === true ? 1 : 0
			}&user_email=${userMail}`
		);
	};

	const findCommonSuppliers = (arr1, arr2) => {
		const suupliersArray = arr1.map((user) => user.DISTRIBUTOR_NAME);
		const commonSuppliers = arr2
			.filter((user) => suupliersArray.includes(user.DISTRIBUTOR_NAME))
			.map((user) => user.DISTRIBUTOR_NAME);

		return {
			commonSuppliers,
			hasCommonSuppliers: commonSuppliers.length > 0,
		};
	};

	const addIsDuplicatedFlag = (data) => {
		const distributorMap = {};

		// First, map each distributor name to the sources they appear in
		data.forEach((entry) => {
			const { DISTRIBUTOR_NAME, DATA_FROM } = entry;
			if (!distributorMap[DISTRIBUTOR_NAME]) {
				distributorMap[DISTRIBUTOR_NAME] = new Set();
			}
			distributorMap[DISTRIBUTOR_NAME].add(DATA_FROM);
		});

		// Then, update each entry with the is_duplicated value
		return data.map((entry) => {
			const { DISTRIBUTOR_NAME } = entry;
			const isDuplicated =
				distributorMap[DISTRIBUTOR_NAME].size > 1 ? 'Yes' : 'No';
			return { ...entry, is_duplicated: isDuplicated };
		});
	};

	const handleFindChips = (e) => {
		setFindChipsSource(e.target.checked);
		axios.post(
			`https://internal.supremecomponents.com/api/externalgateway.php?route=set_cf_config&config_name=FINDCHIPS_AUTH&config_value=${
				e.target.checked === true ? 1 : 0
			}&user_email=${userMail}`
		);
	};

	const getData = async () => {
		setIsLoading(true);
		try {
			const [findChipsResponse, octopartResponse, cfDirect] = await Promise.all(
				[
					axios.get(
						`https://internal.supremecomponents.com/api/externalgateway.php?route=get_fc_auth_sources`
					),
					axios.get(
						`https://internal.supremecomponents.com/api/externalgateway.php?route=get_op_auth_sources`
					),
					axios.get(
						`https://internal.supremecomponents.com/api/externalgateway.php?route=get_all_cf_direct`
					),
				]
			);

			const result = findCommonSuppliers(
				findChipsResponse.data,
				octopartResponse.data,
				cfDirect.data
			);

			setCommonSuppliers(result);

			setData(
				addIsDuplicatedFlag([
					...findChipsResponse.data,
					...octopartResponse.data,
					...cfDirect.data,
				])
			);
			setError(null);
		} catch (err) {
			setError(err.message);
			setData([]);
		} finally {
			setIsLoading(false);
		}
	};

	const getConfig = () => {
		axios
			.get(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=get_cf_configs`
			)
			.then((response) => {
				const data = response.data;
				if (data.OCTOPART) {
					setOctopartSource(data.OCTOPART.AUTHORIZED_ONLY);
				}
				if (data.FINDCHIPS) {
					setFindChipsSource(data.FINDCHIPS.AUTHORIZED_ONLY);
				}
			})
			.catch((error) => {
				console.error('Error fetching config:', error);
			});
	};

	useEffect(() => {
		getConfig();
		getData(); //eslint-disable-next-line
	}, []);

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			resizable: true,
			flex: 2,
			floatingFilter: true,
			minWidth: 100,
			filter: 'agMultiColumnFilter',
			filterParams: {
				filters: [
					{
						filter: 'agTextColumnFilter',
						filterParams: {
							applyMiniFilterWhileTyping: true,
						},
					},
					{
						filter: 'agSetColumnFilter',
						filterParams: {
							buttons: ['reset'],
							applyMiniFilterWhileTyping: true,
						},
					},
				],
			},
		}),
		[]
	);

	const onGridReady = useCallback((params) => {
		setGridApi(params.api);
	}, []);

	/**
	 * On cell value change for isSenior column
	 */
	const onCellValueChanged = useCallback(
		(e) => {
			const cellValue = e.data;

			if (e.column.colId === 'REMARKS') {
				const formData = new FormData();

				formData.append('id', cellValue.ID);
				formData.append('source', cellValue.DATA_FROM);
				formData.append('remarks', cellValue.REMARKS);
				formData.append('name', cellValue.DISTRIBUTOR_NAME);

				axios
					.post(
						'https://internal.supremecomponents.com/api/externalgateway.php?route=add_remark_cf_sources',
						formData
					)
					.then((response) => {
						console.log(response);
					});
			}

			// Function to handle the API call
			const saveData = async (url) => {
				setSavingData(true);
				setSavingDataError(null); // Clear any previous error

				try {
					const response = await axios.post(url);
					console.log(response);

					const column = e.column.colDef.field;
					setTimeout(() => {
						e.api.refreshCells({
							force: true,
							columns: [column],
							rowNodes: [e.node],
						});
						e?.api?.tabToNextCell();
					}, 500);
				} catch (err) {
					console.error('Data save failed:', err);
					setSavingDataError('Failed to save data. Please try again.');
				} finally {
					setSavingData(false);
				}
			};

			if (
				(cellValue.DATA_FROM === 'Findchips' ||
					cellValue.DATA_FROM === 'Octopart') &&
				e.column.colId === 'IS_ENABLED_FOR_CF'
			) {
				const url = `https://internal.supremecomponents.com/api/externalgateway.php?route=cf_operation&data_source=${
					cellValue?.DATA_FROM
				}&provider=${cellValue?.DISTRIBUTOR_NAME}&enabled=${
					cellValue.IS_ENABLED_FOR_CF === true ? 1 : 0
				}&online_offline=${cellValue.ONLINE_OR_OFFLINE === true ? 1 : 0}`;

				// Call the save function
				saveData(url);
			}

			if (
				cellValue.DATA_FROM === 'CF Direct' &&
				e.column.colId === 'IS_ENABLED_FOR_CF'
			) {
				let data = [];
				gridApi?.forEachNode((rowNode) => {
					data.push(rowNode.data);
				});

				const idsTrue = data
					.filter(
						(el) =>
							el.DATA_FROM === 'CF Direct' && el.IS_ENABLED_FOR_CF === true
					)
					.map((el) => el.ID)
					.join(',');

				const idsFalse = data
					.filter(
						(el) =>
							el.DATA_FROM === 'CF Direct' && el.IS_ENABLED_FOR_CF === false
					)
					.map((el) => el.ID)
					.join(',');

				// Call the save function for both enabled and disabled cases
				saveData(
					`https://internal.supremecomponents.com/api/externalgateway.php?route=set_cf_all_direct&ids=${idsTrue}`
				);
				saveData(
					`https://internal.supremecomponents.com/api/externalgateway.php?route=set_cf_all_direct_disabled&ids=${idsFalse}`
				);
			}
		},
		[gridApi]
	);

	useEffect(() => {
		const handleKeyPress = (event) => {
			if (event.ctrlKey && event.shiftKey && event.key === 'L') {
				gridApi.setFilterModel(null);
				gridApi?.setQuickFilter('');
			}
		};

		window.addEventListener('keydown', handleKeyPress);

		return () => {
			window.removeEventListener('keydown', handleKeyPress);
		};
	}, [gridApi]);

	return (
		<>
			{userType === 'admin' ? (
				<div className={styles['wrapper']}>
					<BreadCrumb
						route1="Admin"
						route2="ChipsFinder Sources"
						icon={<MdOutlineAdminPanelSettings style={{ fontSize: '20px' }} />}
					/>
					<SectionInfo
						removeshortcutkeys
						title="Chipfinder Sources"
						info={
							<p>
								This section enables the user to pick and choose which sources
								will be included into ChipsFinder and ChipsGalaxy. <br /> <br />{' '}
								Note: every night the system will keep adding “new” sources that
								were added. An email will be sent to SCI_TECH and Mr. Vick to
								determine whether to activate or not.
							</p>
						}
					/>
					<Divider
						style={{
							margin: '5px 0',
							borderBlockStart: '1px solid rgb(216, 216, 216)',
						}}
					/>
					<div className={styles['chipfinder-octapart-sources']}>
						{isLoading && (
							<div className={styles['loading-wrapper']}>
								<LoadingMessage message="Loading..." />
							</div>
						)}

						{error && !isLoading && (
							<div className={styles['error-wrapper']}>
								<ErrorMessage message="Snap! That sound could be that of your data endpoint going kaput. But don’t worry our engineers are already on it getting it fixed." />
							</div>
						)}

						{!isNotEmptyArray(data) && !error && !isLoading && (
							<div className={styles['no-data-wrapper']}>
								<NoData message="Its all empty here." />
							</div>
						)}

						{!isLoading && !error && isNotEmptyArray(data) && (
							<>
								{commonSuppliers?.hasCommonSuppliers === true && (
									<NotificationBar
										redBar
										message={`There are multiple supplier names with the exact same name that have an “Activate” = Yes. The system has deactivated the Octopart supplier and kept the FindChips supplier.​`}
									/>
								)}
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'space-between',
									}}
								>
									<div>
										<CheckBox
											checked={octopartSource}
											onChange={handleOctopart}
										>
											Always activate authorized Octopart Sources during MPN
											search​
										</CheckBox>
										<CheckBox
											checked={findChipsSource}
											onChange={handleFindChips}
										>
											Always activate authorized FindChips Sources during MPN
											search​
										</CheckBox>
									</div>
									{savingData && <div>Saving data...</div>}
									{savingDataerror && (
										<div style={{ color: 'red' }}>{savingDataerror}</div>
									)}
								</div>
								<div className={styles['table-wrapper']}>
									<div className={`ag-theme-custom ${styles['table']} `}>
										<AgGridReact
											rowData={data}
											columnDefs={columnDefs}
											defaultColDef={defaultColDef}
											animateRows={true}
											pagination={true}
											paginationPageSizeSelector={false}
											onCellValueChanged={onCellValueChanged}
											onGridReady={onGridReady}
										/>
									</div>
								</div>
							</>
						)}
					</div>
				</div>
			) : (
				<AccessDenied message="You are not an Admin" />
			)}
		</>
	);
};

export default ChipfinderOctapartSources;
