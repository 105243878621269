import { memo, useContext, useMemo, useState } from 'react';
import { IoMdAddCircle, IoMdAttach } from 'react-icons/io';
import { Col, Collapse, Form, message, Row, Upload } from 'antd';
import { Base64 } from 'js-base64';
import { CaretRightOutlined } from '@ant-design/icons';
import moment from 'moment';
import axios from 'axios';
import { AgGridReact } from 'ag-grid-react';
import { HiOutlineSearch } from 'react-icons/hi';

import Button from '../../../components/button';
import Input from '../../../components/input';
import Drawer from '../../../components/drawer';
import Select from '../../../components/select';
import EdenContext from '../../../context/edenContext';
import TextArea from '../../../components/textArea';
import UploadedFile from '../../../components/uploadedFile';
import NotificationBar from '../../../components/notificationBar';
import LoadingMessage from '../../../components/loaderMessage';
import ErrorMessage from '../../../components/errorMessage';
import { isNotEmptyArray } from '../../../utils';
import NoData from '../../../components/nodata';
import { dateFilterParams } from '../../../filters';
import MailPreview from '../../../components/MailPreview';
import HelpDeskTrackerContext from '../../../context/helpdeskTrackerContext';

import styles from './composeTicket.module.scss';

const ComposeTicket = () => {
	const { srData } = useContext(EdenContext);
	const { setMailError, getData } = useContext(HelpDeskTrackerContext);
	const [form] = Form.useForm();
	const [drawer, setDrawer] = useState(false);
	const [createingTicket, setCreateingTicket] = useState(false);
	const [fileList, setFileList] = useState([]);
	const [searchValue, setSearchValue] = useState('');
	const [customerProspectData, setCustomerProspectData] = useState([]);
	const [customerProspectLoading, setCustomerProspectLoading] = useState(false);
	const [customerProspectError, setCustomerProspectError] = useState(null);
	const [rowSelectionData, setRowSelectionData] = useState(null);
	const [gridApi, setGridApi] = useState(null);

	const onGridReady = (params) => {
		setGridApi(params.api);
	};

	const [columnDefs] = useState([
		{
			checkboxSelection: true,
			field: 'subject',
			headerName: 'Subject Line',
		},
		{
			field: 'sciCustomer',
			headerName: 'Customer',
		},
		{
			field: 'contactName',
			headerName: 'Customer Name',
		},
		{
			field: 'from',
			headerName: 'From',
		},
		{
			field: 'recipients',
			headerName: 'To',
		},
		{
			field: 'sciSalesRep',
			headerName: 'Sales Rep',
		},
		{
			field: 'receivedDateTime',
			headerName: 'Received Date',
			filterParams: dateFilterParams,
			minWidth: 190,
			sort: 'desc',
			cellRenderer: (p) => (
				<>
					{p.value
						? moment
								.utc(p.value)
								.tz('Asia/Singapore')
								.format('MMM-DD-YYYY hh:mm A')
						: '--'}
				</>
			),
			suppressHeaderMenuButton: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true,
			},
		},
		{
			field: 'webLink',
			headerName: 'Email',
			floatingFilter: false,
			cellRenderer: memo(MailPreview),
			maxWidth: 80,
			cellStyle: { padding: '4px 0 0 25px' },
		},
	]);

	const handleSearchValue = () => {
		if (searchValue?.length > 2) {
			setCustomerProspectLoading(true);
			axios
				.get(
					`https://internal.supremecomponents.com/api/externalgateway.php?route=findmail&keyword=${encodeURIComponent(
						Base64.encode(searchValue)
					)}&acctype=5&pageno=1&rowsperpage=1000`
				)
				.then((response) => {
					setCustomerProspectData(response.data);
					setCustomerProspectError(null);
				})
				.catch((err) => {
					setCustomerProspectError(err);
					setCustomerProspectData(null);
					setCustomerProspectLoading(false);
				})
				.finally(() => {
					setCustomerProspectLoading(false);
				});
		}
	};

	const closeDrawer = () => {
		form.resetFields();
		setRowSelectionData(null);
		setDrawer(false);
		gridApi.deselectAll();
		setFileList([]);
		setCustomerProspectData([]);
	};

	const onFinish = (values) => {
		setCreateingTicket(true);
		setMailError(false);

		const formdata = new FormData();

		formdata.append(
			'employee_id',
			srData?.find((el) => values.employee_name === el.salesRepName)
				?.salesRepCrmId
		);
		formdata.append(
			'employee_email',
			srData?.find((el) => values.employee_name === el.salesRepName)
				?.salesRepEmail
		);
		formdata.append('employee_name', values.employee_name);
		formdata.append('issue_details', values.issue_details);
		formdata.append('ticket_type', values.ticket_type);
		formdata.append('attach_email_id', rowSelectionData[0].cid);
		// fileList.forEach((file) => {
		formdata.append('attachment_file', fileList[0].originFileObj);
		// });

		axios
			.post(
				'https://internal.supremecomponents.com/api/externalgateway.php?route=log_ticket',
				formdata
			)
			.then((response) => {
				message.success('Ticket sent to IT Help Desk');
			})
			.catch((err) => {
				setMailError(true);
			})
			.finally(() => {
				form.resetFields();
				setRowSelectionData(null);
				setCreateingTicket(false);
				setDrawer(false);
				gridApi.deselectAll();
				setFileList([]);
				setCustomerProspectData([]);
				getData();
			});
	};

	const props = {
		name: 'file',
		action: '',
		fileList: fileList,
		showUploadList: false,
		multiple: true,
		maxCount: 5,
		disabled: fileList.length >= 5,
		onChange: (info) => {
			let newFileList = [...info.fileList];

			// Remove files exceeding the size limit
			newFileList = newFileList.filter((file) => {
				if (file.size > 3145728) {
					message.error(`${file.name} is larger than 3MB and was removed`);
					return false;
				}
				return true;
			});

			setFileList(newFileList);
		},
		customRequest: () => null,
	};

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			resizable: true,
			flex: 2,
			floatingFilter: true,
			minWidth: 100,
			filter: 'agMultiColumnFilter',
		}),
		[]
	);

	const onSelectionChanged = (p) => {
		setRowSelectionData(p.api.getSelectedRows());
	};

	const { Panel } = Collapse;

	return (
		<div className={styles['wrapper']}>
			<Button style={{ marginBottom: '10px' }} onClick={() => setDrawer(true)}>
				<IoMdAddCircle style={{ fontSize: '16px' }} />
				Add Ticket
			</Button>

			{drawer && (
				<Drawer
					title={'Create IT HelpDesk Ticket'}
					placement="right"
					onClose={closeDrawer}
					open={drawer}
					width={600}
					footer={
						<div className={styles['drawer-footer']}>
							<Button
								disabled={createingTicket}
								onClick={() => form.submit()}
								loading={createingTicket}
							>
								Send Ticket
							</Button>
							<Button
								disabled={createingTicket}
								type="bordered"
								onClick={closeDrawer}
							>
								Cancel
							</Button>
						</div>
					}
				>
					<Form
						name="help-desk-ticket-form"
						id="help-desk-ticket-form"
						onFinish={onFinish}
						validateTrigger={['onBlur', 'onChange']}
						labelCol={{ span: 24 }}
						requiredMark={false}
						form={form}
						disabled={createingTicket}
					>
						<Row gutter={20}>
							<Col xs={24} md={12}>
								<Form.Item
									label="Requester"
									name="employee_name"
									id="employee_name"
									rules={[
										{
											required: true,
											message: 'Please enter Requester!',
										},
									]}
								>
									<Select
										showSearch
										placeholder="Select Requester"
										type="bordered"
										options={srData?.map((el) => ({
											value: el?.salesRepName,
											label: el?.salesRepName,
											id: el?.salesRepCrmId,
											email: el?.salesRepEmail,
										}))}
									/>
								</Form.Item>
							</Col>
							<Col xs={24} md={12}>
								<Form.Item
									label="Ticket Type"
									name="ticket_type"
									id="ticket_type"
									rules={[
										{
											required: true,
											message: 'Please enter Ticket Type!',
										},
									]}
								>
									<Select
										showSearch
										placeholder="Select Ticket Type"
										type="bordered"
										options={[
											{ value: 'Issue', label: 'Issue' },
											{ value: 'Request', label: 'Request' },
										]}
									/>
								</Form.Item>
							</Col>
							<Col xs={24}>
								<Form.Item
									label="Incident"
									name="issue_details"
									id="issue_details"
									rules={[
										{
											required: true,
											message: 'Please enter Incident!',
										},
									]}
								>
									<TextArea
										autoSize={{ minRows: 6, maxRows: 6 }}
										allowClear={true}
										placeholder="Add Incident"
									/>
								</Form.Item>
							</Col>

							<Col xs={24}>
								<Form.Item
									label="Attach Screenshot/File"
									name="FILE"
									id="attachment"
									style={{ marginBottom: '0' }}
								>
									<Upload {...props}>
										<Button type="bordered" disabled={fileList.length >= 5}>
											<IoMdAttach className={styles['attach-icon']} /> Insert
										</Button>
									</Upload>
									{fileList.length > 0 && (
										<div
											style={{
												display: 'flex',
												alignItems: 'center',
												gap: 10,
												flexWrap: 'wrap',
											}}
										>
											{fileList.map((file) => (
												<UploadedFile
													key={file?.uid}
													url={file?.name}
													style={{ marginTop: '5px' }}
													fileName={file?.name}
													onRemove={() => {
														setFileList(
															fileList.filter((el) => el.uid !== file.uid)
														);
														// form.setFieldsValue({ FILE: '' });
													}}
													canDownload
												/>
											))}
										</div>
									)}
								</Form.Item>
							</Col>

							<Collapse
								accordion
								ghost
								className={styles['collapse-wrapper']}
								expandIconPosition="end"
								expandIcon={({ isActive }) => (
									<CaretRightOutlined rotate={isActive ? 90 : 0} />
								)}
							>
								<Panel
									header={
										<>
											<div className="font-weight-600 font-18">
												Attach Email
											</div>
											<div className="font-13 text-lighter pb-2">
												Please select the email you would like to send to the
												Help Desk.
											</div>
										</>
									}
									key="1"
									className={styles['panel-wrapper']}
								>
									<NotificationBar
										message={
											<ul
												style={{
													listStyle: 'disc',
													paddingLeft: '10px',
												}}
											>
												<li>
													If you don’t see your email. Please close this popup
													and refresh. It usually takes a minute for Eden to
													catch it.
												</li>
												<li>
													Note: Eden can only retrieve emails from the past 10
													days.
												</li>
											</ul>
										}
										wraningBar
									/>
									{searchValue?.length < 3 && (
										<NotificationBar
											redBar
											message="A minimum of 3 characters are required to view the results."
										/>
									)}

									<div className={styles['input-searchButton']}>
										<Input
											placeholder="Search for Subject Line or Sender's Email Address"
											onChange={(e) => setSearchValue(e.target.value)}
											onPressEnter={handleSearchValue}
											disabled={customerProspectLoading}
										/>
										<Button
											onClick={handleSearchValue}
											disabled={
												customerProspectLoading || searchValue?.length < 3
											}
										>
											Search{' '}
											<HiOutlineSearch className={styles['search-icon']} />
										</Button>
									</div>

									{customerProspectLoading && (
										<div className={styles['loading-wrapper']}>
											<LoadingMessage
												height={60}
												width={60}
												message="Loading..."
											/>
										</div>
									)}

									{customerProspectError && !customerProspectLoading && (
										<div className={styles['error-wrapper']}>
											<ErrorMessage
												style={{ width: '200px' }}
												messageStyle={{ fontSize: '14px' }}
												message="Snap! That sound could be that of your data endpoint going kaput. But don’t worry our engineers are already on it getting it fixed."
											/>
										</div>
									)}

									{!isNotEmptyArray(customerProspectData) &&
										!customerProspectError &&
										!customerProspectLoading && (
											<div className={styles['no-data-wrapper']}>
												<NoData
													style={{ width: '200px' }}
													messageStyle={{ fontSize: '14px', width: '300px' }}
													message="Its all empty here. Search for Subject Line or Sender's Email Address"
												/>
											</div>
										)}

									{!customerProspectLoading &&
										!customerProspectError &&
										isNotEmptyArray(customerProspectData) && (
											<div
												className={`ag-theme-custom ${styles['table-wrapper']}`}
											>
												<AgGridReact
													columnDefs={columnDefs}
													onGridReady={onGridReady}
													rowData={customerProspectData}
													defaultColDef={defaultColDef}
													rowSelection={'single'}
													onSelectionChanged={onSelectionChanged}
													animateRows={true}
													pagination={true}
													paginationPageSize={10}
													paginationPageSizeSelector={false}
												/>
											</div>
										)}
								</Panel>
							</Collapse>
						</Row>
					</Form>
				</Drawer>
			)}
		</div>
	);
};

export default ComposeTicket;
