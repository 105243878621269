import { createContext, useState } from 'react';
import axios from 'axios';

const HelpDeskTrackerContext = createContext();

export const HelpDeskTrackerProvider = ({ children }) => {
	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [cascaderOption, setCascaderOption] = useState('');
	const [ticketsView, setTicketsView] = useState('OPEN');
	const [mailError, setMailError] = useState(false);

	const getData = () => {
		setIsLoading(true);
		axios
			.post(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=get_log_tickets&employee_id=${cascaderOption}&status=${ticketsView}`
			)
			.then((response) => {
				setData(response.data?.data);
				setError(null);
			})
			.catch((err) => {
				setError(err);
				setData(null);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	return (
		<HelpDeskTrackerContext.Provider
			value={{
				data,
				isLoading,
				error,
				getData,
				cascaderOption,
				setCascaderOption,
				ticketsView,
				setTicketsView,
				mailError,
				setMailError,
			}}
		>
			{children}
		</HelpDeskTrackerContext.Provider>
	);
};

export default HelpDeskTrackerContext;
