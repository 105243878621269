import Home from './pages/home';
import CustomerReport from './pages/mySalesPerformance/CustomerReport';
import MPNReport from './pages/mySalesPerformance/MPNReport';
import CustomerContactEngagement from './pages/myEmailEngagement/CusContEngagementV2';
import MPNCostBook from './pages/mySources/MPNCostBook';
import MPNQueue from './pages/mySources/MPNQueue';
import NetComponents from './pages/NetComponents';
import FreightTable from './pages/FreightTable';
import EPAccTags from './pages/admin/EPAccTags';
import SourcingAssignments from './pages/admin/SourcingAssignments';
import BobcatExclusionList from './pages/admin/BobcatExclusionList';
import APITokenLimits from './pages/admin/APITokenLimits';
import ChahGPTUsageReport from './pages/admin/ChatGPTUsageReport';
import SupplierPrices from './pages/mySources/supplierPrices';
import HouseAccounts from './pages/houseAccounts';
import HouseAccountsAdmin from './pages/admin/HouseAccount';
import ChatGptKeywords from './pages/admin/ChatGptKeywords';
import AssignToHouse from './pages/assignToHouse';
import ManagementReport from './pages/managementReport1';
import AccountAllocationLog from './pages/admin/AccountAllocationLog';
import BounceBackTracker from './pages/BounceBackTrackerV2';
import SRAccountsSummary from './pages/admin/SRAccountsSummary';
import HouseAccountExclusions from './pages/admin/HouseAccountExclusions';
import CustomerContacts from './pages/customerContacts';
import CustomerOutreach from './pages/CustomerOutreach';
import LinkedInCleanser from './pages/admin/LinkedInCleanser';
import GlobalChatGptReport from './pages/admin/GlobalChatgptReport';
import CustomerPrices from './pages/CustomerPrices';
import MassMail from './pages/MassMail';
import APIHealthChecker from './pages/admin/APIHealthChecker';
import ChipfinderOctapartSources from './pages/admin/ChipfinderOctapartSources';
import PreferredsuppliersbyBrand from './pages/PreferredsuppliersbyBrand';
import SalesRepReportTracker from './pages/admin/SalesRepReportTracker';
import HelpDeskTracker from './pages/HelpDeskTracker';

const router = [
	{ path: '/', element: <Home /> },
	{ path: '/house-accounts', element: <HouseAccounts /> },
	{ path: '/assign-to-house', element: <AssignToHouse /> },
	{ path: '/customer-contacts', element: <CustomerContacts /> },
	{ path: '/management-report', element: <ManagementReport /> },
	{ path: '/customer-outreach', element: <CustomerOutreach /> },
	{ path: '/mass-mail', element: <MassMail /> },
	{ path: '/customer-report', element: <CustomerReport /> },
	{ path: '/mpn-report', element: <MPNReport /> },
	{ path: '/email-engagement', element: <CustomerContactEngagement /> },
	{ path: '/mpn-queue', element: <MPNQueue /> },
	{ path: '/customer-rfqs', element: <CustomerPrices /> },
	{ path: '/supplier-prices', element: <SupplierPrices /> },
	{ path: '/my-sources/supplier-prices?mpn=', element: <SupplierPrices /> },
	{ path: '/netcomponents', element: <NetComponents /> },
	{ path: '/freight-by-supplier', element: <FreightTable /> },
	{
		path: '/preferred-suppliers-by-brand',
		element: <PreferredsuppliersbyBrand />,
	},
	{ path: '/bounceback-tracker', element: <BounceBackTracker /> },
	{ path: '/mpn-cost-book', element: <MPNCostBook /> },
	{ path: '/help-desk-tracker', element: <HelpDeskTracker /> },
	{ path: '/admin/chatgpt-keywords', element: <ChatGptKeywords /> },
	{ path: '/admin/eagle-processor-account-tags', element: <EPAccTags /> },
	{ path: '/admin/sourcing-assignments', element: <SourcingAssignments /> },
	{
		path: '/admin/bobcat-eagle-exclusions-list',
		element: <BobcatExclusionList />,
	},
	{ path: '/admin/linkedin-cleanser', element: <LinkedInCleanser /> },
	{ path: '/admin/global-chatgpt-report', element: <GlobalChatGptReport /> },
	{ path: '/admin/chatgpt-usage-report', element: <ChahGPTUsageReport /> },
	{ path: '/admin/api-token-limits', element: <APITokenLimits /> },
	{ path: '/admin/house-account', element: <HouseAccountsAdmin /> },
	{ path: '/admin/account-allocation-log', element: <AccountAllocationLog /> },
	{ path: '/admin/sr-accounts-summary', element: <SRAccountsSummary /> },
	{
		path: '/admin/house-account-exclusion',
		element: <HouseAccountExclusions />,
	},
	{
		path: '/admin/api-health-checker',
		element: <APIHealthChecker />,
	},
	{
		path: '/admin/chipfinder-sources',
		element: <ChipfinderOctapartSources />,
	},
	{
		path: '/admin/sales-report-tracker',
		element: <SalesRepReportTracker />,
	},
];

export default router;
